<template>
    <div class="tableData" style="font-size: 10px;">
      <table border="1" rules="all">
        <tr>
          <th colspan="3" style="text-align: center;">个人信息收集清单</th>
        </tr>
        <tr>
          <th>场景/业务功能</th>
          <th>个人信息种类</th>
          <th>目的</th>
        </tr>
        <tr>
          <td rowspan="2">注册/登录</td>
          <td>手机号、验证码、密码、IMEI、MAC地址、IMSI、OAID、Android ID</td>
          <td>用于注册创建账号和安全登录</td>
        </tr>
        <tr>
          <td>头像、昵称</td>
          <td>用于完善网络身份识别（非必要信息，不提供不影响使用）</td>
        </tr>
        <tr>
          <td>搜索功能</td>
          <td>搜索关键词信息</td>
          <td>使用此类技术可以帮助您省去重复输入搜索内容的步骤和流程</td>
        </tr>
        <tr>
          <td>社交聊天类应用分身运行</td>
          <td>设备相关信息（设备型号、操作系统版本、设备设置、IMEI、MAC地址、IMSI、OAID、Android ID、IP地址）、运行进程列表、已安装的应用程序列表信息</td>
          <td>用于接收和通知提醒实时消息</td>
        </tr>
        <tr>
          <td>地图类应用分身运行</td>
          <td>地理位置信息</td>
          <td>地图类应用程序分身需要实时获取您的位置以便实现导航功能</td>
        </tr>
        <tr>
          <td>支付</td>
          <td>支付时间、支付金额、支付渠道</td>
          <td>用于协助用户完成在线支付、支付订单查询功能</td>
        </tr>
        <tr>
          <td>设置行程和活动</td>
          <td>开启系统提醒功能、日历权限</td>
          <td>设置行程的预定和提醒服务，将行程添加至日历</td>
        </tr>
        <tr>
          <td>社交类应用分身运行</td>
          <td>手机号、通讯录、运行进程列表、地理位置信息</td>
          <td>用户自愿转发或分享的主动行为</td>
        </tr>
        <tr>
          <td>互动场景</td>
          <td>身体传感器数据</td>
          <td>用于在扫一扫场景下通过光线传感器可判断黑暗环境、播放视频时通过重力传感器适配横竖屏、摇一摇互动场景下识别用户动作、运动时通过身体传感器获取心率状况</td>
        </tr>
        <tr>
          <td>使用过程中的运营与安全保障</td>
          <td>设备相关信息（IMEI、MAC地址、IMSI、OAID、Android ID、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（IP 地址）、网络信息、已安装的应用程序列表信息</td>
          <td>用于用户安全保障</td>
        </tr>
        <tr>
          <td>客服与售后</td>
          <td>用户与客服的联系记录、账号信息、身份核验信息、订单信息、拍照上传图片、语音沟通记录</td>
          <td>用于核实用户身份并解决所必要的信息</td>
        </tr>
        <tr>
          <td>个性化推荐</td>
          <td>设备相关信息（IMEI、MAC地址、IMSI、OAID、Android ID、设备机型、操作系统及版本、客户端版本）、本机已安装的软件列表、用户使用行为（双开的应用的添加及启动行为记录）</td>
          <td>用于推荐、展示热门双开应用</td>
        </tr>
        <tr>
          <td>统计分析</td>
          <td>操作路径、按钮点击行为、应用程序的添加和启动行为、激活、留存、网络信息、位置信息、已安装的应用程序列表信息</td>
          <td>用于增长分析、A/B测试分析的数据采集</td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: no-repeat 0 0;
    background-size: 100% auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    height: auto;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    background-color: #ffffff;
  }
  li {
    list-style: none;
    padding: 0.5em 0;
    width: 100%;
  }
  .tableData {
    width: 100%;
    overflow: auto;
  }
  table {
    border-collapse: collapse;
    width: 600px;
  }
  table,
  table tr th,
  table tr td {
    white-space: nowrap;
  }
  th,
  td {
    padding: 0.5em;
  }
  #app {
    font-family: -apple-system-font, BlinkMacSystemFont, "Helvetica Neue",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei",
    Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8em;
  }
  .right {
    text-align: right;
  }
  .middle {
    text-align: center;
  }
  p {
    line-height: 1.6em;
    word-break: break-all;
    padding: 0.4em 0;
  }
  h3,
  h4 {
    padding: 0.6em 0;
    margin-bottom: 0.5em;
  }
  .layout {
    padding: 1em 4%;
    letter-spacing: 1px;
  }
  .title {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 2em;
    text-align: center;
  }
  .sub_list > * {
    padding: 0.6em 0;
  }
</style>